import React from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Pd_dogs from "../images/case/pd-dogs.jpg"
import Pd_img1 from "../images/case/pd-img-1.jpg"
import Pd_img2 from "../images/case/pd-img-2.jpg"
import Pd_img3 from "../images/case/pd-img-3.jpg"
import Pd_logo from "../images/case/pd-logo.png"
import Pd_dog_ico from "../images/case/pd-dog-ico.gif"
import Pd_graph from "../images/case/pd-graph-img.jpg"
import Pd_rightarrow from "../images/case/pd-right-arrow.png"
import Pd_dogwindow from "../images/case/pd-dog-window.jpg"
import Pd_bottom from "../images/case/pd-btm-img.jpg"
import Scroll_top_img from "../images/scroll-arrow.png"
import {Helmet} from "react-helmet/es/Helmet"

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'

export default () => (
    <Layout>
        <Helmet>
            <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
            <meta name="description"
                  content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
            <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
            <meta name="description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:site_name" content="Cueblocks"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
            <meta property="og:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta property="og:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta name="twitter:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta name="twitter:site" content="@cueblocks"/>
            <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <div className="cb_expertiseInner">
            <div className="case_study_page ">
            <div className="case-wrap pet-wrap ">
                <div className="ww-sec-one ">
                    <div className="container">

                        <h3>Case Study</h3>
                            <ScrollAnimation animateIn="slideInUp" animateOnce="true">
                        <h1><span>PETDOORS.COM</span></h1>
                        </ScrollAnimation>
                        <p className="ft-sndry sitespeed_p">How merging revenue-driven blog posts lead to <span>106.94%</span> increase in organic sessions for
                            PetDoors.com</p>

                        <div className="ww-top-img"><img src={Pd_dogs} alt="PetDoors"/></div>
                        <div className="ww-main-heading">PetDoors.com stocks one of the world's largest selections of built-to-last,
                          weather-tight pet doors. The company already has 500,000 pet owners on board, providing the most suitable door
                             for dogs & cats of all sizes.</div>

                        <div className="pd-main-desc">
                            <p>Their plethora of experience in the industry has made them understand pet doors more than anyone else in the
                              world. To add to this, the company is well aware of the nuances of how blogs can help gain market share in
                              organic traffic. As a result, they've been consistently covering all nitty-gritty of information around pet
                                doors via their blogs.</p>
                            <p>However, when someone publishes content over a long period of time, content pieces with overlapping
                              information can occur naturally. These overlapping content led to a rise in visibility of one content piece
                             while the other pieces faded in the shadow.</p>
                        </div>

                        <div className="pd-main-images">
                            <span><img src={Pd_img1} alt="PetDoors" /></span>
                            <span><img src={Pd_img2} alt="PetDoors" /></span>
                            <span><img src={Pd_img3} alt="PetDoors" /></span>
                        </div>

                        <div className="pd-logo-link ">
                            <a href="https://www.petdoors.com" target="_blank" rel="noopener noreferrer" >
                                <img src={Pd_logo} alt="PetDoors" />
                                
                                </a>
                        </div>

                    </div>
                </div>

                <div className="pd-sec-feature ">
                    <div className="container">
                        <p>To derive maximum benefit from their blog posts, CueBlocks assisted Petdoors.com to merge and optimize their
                        blog posts with overlapping content.</p>
                    </div>
                </div>
                
                <div className="pd-sec-result pd-inner-container ww-sec-common ">
                    <div className="container">

                        <div className="pd-result-inner ">
                            <h3 className="heading_main">Results</h3>
                            <h4>Petdoors Experienced</h4>


                            <div className="pd-result-desc">
                                <p><span><img src={Pd_dog_ico} alt="PetDoors" /></span>
                                    <i>106.94%</i> increase in Sessions from their Revenue-driven blog posts</p>
                                    <img src={Pd_graph} alt="PetDoors" />
                               
                            </div>
                        </div>

                    </div>
                </div>

                <div className="pd-sec-blog pd-inner-container ww-sec-common ">
                    <div className="container">

                        <div className="pd-blog-inner ">
                            <h2 className="heading_main">What We Did</h2>

                            <div className="pd-top-wrap ">
                                <div className="pd-left">
                                    <h3>Prioritization of the blog posts</h3>
                                    <p>The importance of prioritization in SEO is often overlooked when there’s a flurry of activities & you are
                                      frantically ticking off the things while hoping for some initial results to kick in.
                                    <br /><br />
                                        As a part of our process here, blog posts were grouped under themes. The themes were provided by the
                                        client, as they were the best source of information on what resonates the most with pet doors. For e.g
                                    </p>
                                    <span className="pd-right-arrow pd-right-arrow-desk ">
                                        <img src={Pd_rightarrow} alt="PetDoors" />
                                      
                                        </span>
                                    <span className="pd-dog-window ">
                                        <ScrollAnimation animateIn="zoomIn" animateOnce="true">
                                           <img src={Pd_dogwindow} alt="PetDoors" />
                                        </ScrollAnimation>
                                        </span>

                                </div>

                                <div className="pd-right">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Themes</td>
                                                <td>Blog posts to be merged</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Blog posts about <br />Pet door training</td>
                                                <td>Blog 1 (Parent Blog) <br />Blog 2 <br />Blog 3</td>
                                            </tr>
                                            <tr>
                                                <td>Blog posts about <br />Pet door Security</td>
                                                <td>Blog 1 (Parent Blog) <br />Blog 2 <br />Blog 3</td>
                                            </tr>
                                            <tr>
                                                <td>Blog posts about Pet door <br />Electronic Pet doors</td>
                                                <td>Blog 1 (Parent Blog) <br />Blog 2 <br />Blog 3</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3>Adding & Retaining the <br />right keywords</h3>
                                    <p>Picking a parent blog post and merging the rest of related (underperforming) blogs was the first step.
                                      The second step was to be wary of keyword placement. All keywords that were ranking well were untouched.
                                      Instead, we added semantically related keywords and synonymous versions of high ranking keywords while
                                     merging.</p>
                                </div>
                            </div>

                            <div className="pd-btm-wrap ">
                                <h2 className="heading_main">An important exception</h2>
                                <p>Please note, not all blog posts are a right fit for merging. A blog performing well on its own might have
                                  its performance compromised when merged and hence, before qualifying any blog for merging, you should make
                                    an exception for the following:</p>
                                <ul>
                                    <li><em>Blog posts doing well in Social Media or newsletters</em></li>
                                    <li><em>Blog posts that are part of buying funnel (even with less number of Sessions)</em></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="get-in-touch-casestudy-section">
                    <h3>Reach out to us to understand<br/> how we can add value to your business.</h3>
                        <a href="/contact" className="main_cta">Get In Touch</a>
                </div>
                    <div className="pd-btm-image ">
                    <img src={Pd_bottom} alt="PetDoors" className="display-block" /> 
                    </div>

                <div id="scroll-to" className="case-scroll">
                    <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                </div>

            </div>
         </div>
        </div>

            <Clutch />

    </Layout>

        )